// hook/useManualServerSentEvents.ts
import { useCallback, useEffect, useState ,useRef} from 'react';

export const useManualServerSentEvents = (url: string, body: any,setIsLoading:any,clearFileId:any,setStop:any,showTips:any,setFlag:any,getInfo:any,resetFn:any, headers?: HeadersInit) => {
    const [messages, setMessages] = useState('' as any);
    const [controller, setController] = useState<AbortController | null>(null);
    const clearTag=useRef(true)
    const accumulatedContent=useRef('')
    const startFetching = useCallback((data:any) => {
        const newController = new AbortController();
        setController(newController);
        const signal = newController.signal;
        // debugger
        const fetchData = async () => {
            try {
                //setIsLoading(true)
                const response:any = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        ...headers,
                        Authorization : 'Bearer ' + localStorage.getItem('token')
                        
                    },
                    body: JSON.stringify(data),
                    signal,
                });
               // debugger
                if (response.body) {
                  //  setIsLoading(false)
                    const reader = response.body.getReader();
                    const decoder = new TextDecoder();
                    let accumulatedStr = '';
                    let str = ''
                    accumulatedContent.current=''
                    while (true) {
                        const { done, value } = await reader.read();
                       
                        if (done) {
                            clearTag.current=true//清空上一次的内容
                            accumulatedContent.current=''
                            resetFn()
                            break;
                        }
                        accumulatedStr += decoder.decode(value, { stream: true });
                        const lines = accumulatedStr.split('\nEND_OF_MESSAGE\n');
                          accumulatedStr =  lines.pop() || ''; // 保存最后的部分，如果是未完成的 JSON 对象
                            for (const line of lines) {
                            if (line.trim() !== '') {
                                try {
                                    
                                const parsed = JSON.parse(line);
                               // console.log(clearTag.current,'-----------------',parsed.content)
                                if (parsed.type === 'message') {
                                    accumulatedContent.current+=parsed.content
                                    // setMessages((prevMessages: any) => {
                                    //    return clearTag.current? ('哈哈哈哈' + parsed.content):(prevMessages + parsed.content)
                                    // });
                                    // clearTag.current=false
                                }
                                } catch (error) {
                                   console.error('Error parsing JSON:', error);
                                }
                            }
                            }
                           // console.log('??????????????????????',accumulatedContent)
                             setMessages(accumulatedContent.current)
                            clearFileId()

                    }
                    // 处理剩余的未完成的 JSON 对象
                        if (accumulatedStr.trim() !== '') {
                            try {
                                const parsed = JSON.parse(accumulatedStr);
                                if (parsed.type === 'message') {
                                    setMessages((prevMessages: any) => prevMessages + parsed.content);
                                }else if(parsed.code === 400){//对话不存在
                                    setStop(false)
                                    showTips(parsed.message,false)
                                    setMessages(parsed.message)
                                }else if(parsed.code === 402){//余额不足
                                    getInfo()
                                    setStop(false)
                                    showTips(parsed.message,false)
                                    setMessages(parsed.message)
                                    setFlag(true)
                                }
                                else if(parsed.code === 500){
                                    getInfo()
                                    setStop(false)
                                    showTips(parsed.message,false)
                                }
                            } catch (error) {
                                console.error('Error parsing remaining JSON:', error);
                            }
                        }
                }
            } catch (error : any) {
                if (error.name === 'AbortError') {
                    // Fetch was aborted
                    console.log('Fetch aborted');
                } else {
                    console.error("Fetch error:", error);
                }
                setIsLoading(false)
            }
        };

        fetchData();
    }, [url, body, headers]);

    const stopFetching = useCallback(() => {
        if (controller) {
            controller.abort();
            setController(null);
        }
    }, [controller]);
    // Cleanup on component unmount
    useEffect(() => {
        return () => {
            if (controller) {
                controller.abort();
            }
        };
    }, [controller]);

    return { messages, startFetching, stopFetching,setMessages};
};