
import styles from '../styles/chat.module.scss'
import { ReactElement, useMemo, useEffect, useRef, useState, useCallback } from 'react'
import Router, { useRouter } from 'next/router';
import ReactMarkdown from 'react-markdown';
import http from "../http";
import Loading from '../components/loading';
import Tips from '../components/tips';
import { useManualServerSentEvents } from '../hook/useManualServerSentEvents';
import TypingEffect from '../components/TypingEffect';
import CopyButton from '../components/CopyButton'
import Setting from '../components/Setting'
import { useTheme } from '../context/ThemeContext';
import { title } from 'process';
export default function Index() {
    const router = useRouter();
    const { github_id } = router.query;
    const markdown = useRef('');
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [displayTips, setDisplayTips] = useState(false)
    const [messageInfo, setMessageInfo] = useState({})
    const [chatList, setChatList] = useState([])
    const [text, setText] = useState('');
    const thread_id = useRef('' as any)
    const [chatContent, setChatContent] = useState([] as any)
    const [currentList, setCurrentList] = useState('' as any)
    const [userInfo, setUserInfo] = useState({} as any)
    const [fileName, setFileName] = useState('点击上传代码zip包')
    const file_id = useRef('' as any)
    const [settingShow, setSettingShow] = useState(false)
    const { theme, toggleTheme } = useTheme();
    const [stop, setStop] = useState(false)
    const stopEnter = useRef(false)
    const [chatpdfTips, setChatpdfTips] = useState(true)
    const chatContainerRef = useRef({} as any);
    const [flag, setFlag] = useState(false)
    const [autoScroll, setAutoScroll] = useState(true);
    const typeRef = useRef('' as any);
    const [cancleTag, setCancleTag] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [showGptsDiv, setShowGptsDiv] = useState(true);
    const [visit_page, setVisit_page] = useState(0)
    const handleScroll = useCallback(() => {
        if (!chatContainerRef.current) return;
        const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
        const isAtBottom = scrollHeight - scrollTop - clientHeight < 100;
        setAutoScroll(isAtBottom);
    }, []);

    const toggleSidebar = () => {
        setIsSidebarCollapsed(!isSidebarCollapsed);
    };
    const tips = 'https://github.com/LI-Mingyu/cndevtutorial 中 GRAPE-operator 的任务定时删除如何实现的？'
    const tips2 = '分析 https://github.com/vesoft-inc/nebula 中 Raft 算法的具体实现？'
    useEffect(() => {
        // createThread()
        // getChatList()
        getInfo()
    }, [])
    useEffect(() => {
        //debugger
        if (router.isReady) {
            // 路由参数已准备好，可以进行操作
            router.query.github_id && setSettingShow(true)
            if (!router.query.thread_id) {
                createThread()
            } else {
                getChatList()
                setCurrentList(router.query.thread_id)
                getChatLog(router.query.thread_id)
                thread_id.current = router.query.thread_id
            }
        }
    }, [router.isReady])
    useEffect(() => {
        const chatContainer = chatContainerRef.current;
        if (chatContainer && !chatpdfTips) {
            chatContainer.addEventListener('scroll', handleScroll);

            return () => {
                chatContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, [chatpdfTips, handleScroll]);
    useEffect(() => {
        const textarea = document.querySelector('textarea');
        if (!isExpanded && textarea) {
            textarea.style.height = '38px'; // 恢复到初始高度
        }
    }, [isExpanded]);
    useEffect(() => {
        if (chatContainerRef.current && autoScroll) {
            const { scrollHeight, clientHeight } = chatContainerRef.current;
            chatContainerRef.current.scrollTop = scrollHeight - clientHeight;
          }
    }, [chatContent, autoScroll]); // 在 chatContent 或 autoScroll 变化时触发
    const handleInputChange = (e) => {
        const textarea = e.target;
        textarea.style.height = 'auto'; // 先重置高度
        textarea.style.height = `${textarea.scrollHeight}px`; // 根据内容调整高度
        if (textarea.scrollHeight > 38) {
            setIsExpanded(true); // 如果 textarea 内容超出初始高度，展开 input 容器
        } else {
            setIsExpanded(false); // 否则恢复初始状态
        }
        markdown.current = textarea.value;
        setText(textarea.value);
    };
    const handleBlur = () => {
        setIsExpanded(false); // 当失去焦点时，恢复 input 容器到初始状态
    };
    const clearFileId = () => {
        file_id.current = null
        setFileName('')
    }
    // 提示消息
    const showTips = (message: String, isSuccess: Boolean) => {
        setDisplayTips(true)
        setMessageInfo({
            message,
            isSuccess
        })
        setTimeout(() => {
            setDisplayTips(false)
        }, 3000)
    }
    const getInfo = () => {
        setIsLoading(true)
        http.post(`/api/v1/account/info`).then(
            (data: any) => {
                setIsLoading(false)
                if (data.status) {
                    if (data.data.subscription_expired) {
                        const [datePart] = data.data.subscription_expired.split(' ');
                        data.data.subscription_expired = datePart;
                    }
                    setUserInfo(data.data)
                    //  showTips(data.message, true);
                } else {
                    showTips(data.message, false);
                }
            }
        );
    }
    const resetFn = () => {
        setStop(false)
        stopEnter.current = false
        markdown.current = ''
    }
    const {
        messages,
        startFetching,
        stopFetching,
        setMessages,
    } = useManualServerSentEvents('/api/v1/chat/chat', { file_id: file_id.current, thread_id: thread_id.current, message: markdown.current }, setIsLoading, clearFileId, setStop, showTips, setFlag, getInfo,resetFn);
    const giToBottom = () => {
        // console.log(autoScroll,chatContainerRef.current.scrollHeight,chatContainerRef.current.scrollTop)
        if (chatContainerRef.current && autoScroll) {
            const { scrollHeight, clientHeight } = chatContainerRef.current;
            chatContainerRef.current.scrollTop = scrollHeight ;
          }

    }
    // Combine messages and replace '\n\n' with HTML line break '<br /><br />'
    const combinedMessages = useMemo(() => {
        // setStop(false)

        let str = ''
        // messages.map((message: any) => message.content ? str += message.content : null);
        chatContent[chatContent.length - 1] = { text: messages, user_type: 'assistant' }
        setChatContent([...chatContent])
        // return str.replace(/\n\n/g, '<br /><br />');
        return str
    }, [messages]);


    // 创建对话
    const createThread = () => {
        // debugger
        setStop(false)
        http.post(`/api/v1/chat/thread`).then(
            (data: any) => {
                setIsLoading(false)
                //debugger
                // this.ControlMessage('加载完成', true);
                if (data.status) {
                    if (data.code == 402) {
                        setFlag(true)
                        getInfo()
                        return
                    }
                    thread_id.current = data.thread_id
                    Router.push(`/?thread_id=${data.thread_id}`)
                    setCurrentList(thread_id.current)
                    //  showTips(data.message, true);

                } else {
                    showTips(data.message, false);
                }
                getChatList()

            }, (err) => {
                console.log(err)
                setIsLoading(false)
            }
        ).catch(res => {

        })
    }
    // 聊天
    const createChat = () => { //点击发送
        if (flag) {
            setText('')
            showTips('您的今日试用对话机会已用完，请明天继续使用，或者进行会员订阅', false);
            return
        }
        if (markdown.current.trim() == '') {
            showTips('请输入您需要解读的内容', false);
            return
        }
        typeRef.current && typeRef.current.setStopTimer()
        setChatpdfTips(false)
        setStop(true)
        setAutoScroll(true);
        setChatContent([...chatContent, { text: markdown.current, user_type: "user" }, { text: 'loading', user_type: 'assistant' }])
        setText('')
        setIsExpanded(false);
        setCancleTag(false)
        startFetching({ file_id: file_id.current, thread_id: thread_id.current, message: markdown.current })

    }
    // 获取对话列表
    const getChatList = () => {
        setIsLoading(true)
        http.get(`/api/v1/chat/chatlist`).then(
            (data: any) => {
                setIsLoading(false)
                if (data.status) {
                    setChatList(data.data)
                    //   showTips(data.message, true);
                } else {
                    showTips(data.message, false);
                }

            }, (err) => {
                console.log(err)
                setIsLoading(false)
            }
        );
    }
    // 获取聊天历史
    const getChatLog = async (threadId: any) => {
         let res: any = await cancleChat()
         if(!res) { return }
        Router.push(`/?thread_id=${threadId}`)
        thread_id.current = threadId
        
        setText('')
        typeRef.current && typeRef.current.setStopTimer()
        setCancleTag(false)
        stopFetching()
        resetFn()
        setIsLoading(true)
        setSettingShow(false)
        http.get(`/api/v1/chat/chat_history/?thread_id=${threadId}`).then(
            (data: any) => {
                setIsLoading(false)
                if (data.status) {
                    if (data.data.length == 0) {
                        setChatpdfTips(true)
                    }
                    else {
                    setChatpdfTips(false)
                    }
                    setChatContent(data.data)
                    //   showTips(data.message, true);
                } else {
                    showTips(data.message, false);
                }
            }
        );
    }

    const createNewChat = async () => {

        setCancleTag(false)
        setChatpdfTips(true)
        setSettingShow(false)
        setChatContent([])
        setText('')
        // setMarkdown('')
        markdown.current = ''
        typeRef.current && typeRef.current.setStopTimer()
        let res: any = await cancleChat()
       // debugger
        if (!res) { return }
        createThread()

    }
    const uploadFile = (e: any) => {
        e.preventDefault();
        // 检查文件后缀是否为 .zip
        const file = e.target.files?.[0];
    
        if (!file) {
            showTips('请选择一个文件', false);
            return;
        }
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        if (fileExtension !== 'zip') {
            showTips('只能上传 ZIP 文件', false);
            return;
        }

        setIsLoading(true)
        let formData = new FormData()
        formData.append('file', e.target.files[0])
        http.post('/api/v1/chat/file', formData).then((res: any) => {
            if (res.openai_file_id) {
                showTips('上传成功', true);
                file_id.current = res.openai_file_id
                setFileName(res.original_filename)
            } else {
                showTips('上传失败', false);
            }
            setIsLoading(false)
        })
    }
    const deleteChat = (uuid: any) => {
        setIsLoading(true)
        http.delete(`/api/v1/chat/chat/${uuid}`).then(
            (data: any) => {
                if (data.status) {
                    getChatList()
                    //  showTips(data.message, true);
                } else {
                    showTips(data.message, false);
                }
                setIsLoading(false)
            }
        );
    }
    const keyupFun = (e: any) => {
        const { code, shiftKey } = e
        if (code == 'Enter' && !shiftKey &&!e.nativeEvent.isComposing) {
            if (flag) {
                showTips('您的今日试用对话机会已用完，请明天继续使用，或者进行会员订阅', false);
                setText('')
                return
            }
            if (stopEnter.current) return
            if (markdown.current.trim() === '') {
                showTips('请输入您需要解读的内容', false);
                return
            }
            setChatpdfTips(false);
            setStop(true)
            setIsExpanded(false);
            typeRef.current && typeRef.current.setStopTimer()
            setAutoScroll(true);
            stopEnter.current = true
            setChatContent((prevChatContent: any) => [
                ...prevChatContent,
                { text: markdown.current, user_type: 'user' },
                { text: 'loading', user_type: 'assistant' },
            ]);
            setText('')
            setCancleTag(false)
            startFetching({ file_id: file_id.current, thread_id: thread_id.current, message: markdown.current });
        }
    }
   
    const cancleFn = () => { //取消发送
        // debugger
        if (typeRef.current) { // 清除定时器，存在typeRef说明已经接口返回问题答案
            typeRef.current.clearTimeout();
        }
        if (chatContent[chatContent.length - 1].text === 'loading') { //点击取消如果最后一条是内容还是loading，说明答案未返回，直接修改成取消
            chatContent[chatContent.length - 1].text = '已取消'
            setChatContent([...chatContent])
        }
        cancleChat()
        stopFetching();
        resetFn();
        setCancleTag(true)


    }
    const cancleChat = () => { //取消与后端的聊天
        return new Promise((resolve, reject) => {
            http.post('api/v1/chat/cancel', { thread_id: thread_id.current }).then((data: any) => {
                if (data.status) {
                    resolve(true)
                    // getChatList()
                    //  showTips(data.message, true);
                } else {
                    resolve(false)
                    showTips(data.message, false);
                }
            })
        })
    }
    return (

        <div className={styles.chatpdf}>
            <div className={`${styles.pannel} ${isSidebarCollapsed ? styles.collapsed : ''}`}>
                <div className={styles.sidebarControls}>
                    <div className={styles.toggleSidebar} onClick={toggleSidebar}>
                        {isSidebarCollapsed ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" className="icon-xl-heavy">
                            <path fill="currentColor" fill-rule="evenodd" d="M8.857 3h6.286c1.084 0 1.958 0 2.666.058.729.06 1.369.185 1.961.487a5 5 0 0 1 2.185 2.185c.302.592.428 1.233.487 1.961.058.708.058 1.582.058 2.666v3.286c0 1.084 0 1.958-.058 2.666-.06.729-.185 1.369-.487 1.961a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C17.1 21 16.227 21 15.143 21H8.857c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.232-.487-1.961C1.5 15.6 1.5 14.727 1.5 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.728.185-1.369.487-1.96A5 5 0 0 1 4.23 3.544c.592-.302 1.233-.428 1.961-.487C6.9 3 7.773 3 8.857 3M6.354 5.051c-.605.05-.953.142-1.216.276a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216-.05.617-.051 1.41-.051 2.546v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h.6V5h-.6c-1.137 0-1.929 0-2.546.051M11.5 5v14h3.6c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.134-.263.226-.611.276-1.216.05-.617.051-1.41.051-2.546v-3.2c0-1.137 0-1.929-.051-2.546-.05-.605-.142-.953-.276-1.216a3 3 0 0 0-1.311-1.311c-.263-.134-.611-.226-1.216-.276C17.029 5.001 16.236 5 15.1 5zM5 8.5a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1M5 12a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1" clip-rule="evenodd"></path>
                        </svg>
                        ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" className="icon-xl-heavy">
                            <path fill="currentColor" fill-rule="evenodd" d="M8.857 3h6.286c1.084 0 1.958 0 2.666.058.729.06 1.369.185 1.961.487a5 5 0 0 1 2.185 2.185c.302.592.428 1.233.487 1.961.058.708.058 1.582.058 2.666v3.286c0 1.084 0 1.958-.058 2.666-.06.729-.185 1.369-.487 1.961a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C17.1 21 16.227 21 15.143 21H8.857c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.232-.487-1.961C1.5 15.6 1.5 14.727 1.5 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.728.185-1.369.487-1.96A5 5 0 0 1 4.23 3.544c.592-.302 1.233-.428 1.961-.487C6.9 3 7.773 3 8.857 3M6.354 5.051c-.605.05-.953.142-1.216.276a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216-.05.617-.051 1.41-.051 2.546v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h.6V5h-.6c-1.137 0-1.929 0-2.546.051M11.5 5v14h3.6c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.134-.263.226-.611.276-1.216.05-.617.051-1.41.051-2.546v-3.2c0-1.137 0-1.929-.051-2.546-.05-.605-.142-.953-.276-1.216a3 3 0 0 0-1.311-1.311c-.263-.134-.611-.226-1.216-.276C17.029 5.001 16.236 5 15.1 5zM5 8.5a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1M5 12a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1" clip-rule="evenodd"></path>
                        </svg>
                        )}
                    </div>
                    {!isSidebarCollapsed && (
                        <div className={styles.logoContainer}>
                            <div className={styles.logo}></div>
                        </div>
                    )}
                    <div className={styles.editIcon} onClick={createNewChat}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24" class="icon-xl-heavy"><path d="M15.673 3.913a3.121 3.121 0 1 1 4.414 4.414l-5.937 5.937a5 5 0 0 1-2.828 1.415l-2.18.31a1 1 0 0 1-1.132-1.13l.311-2.18A5 5 0 0 1 9.736 9.85zm3 1.414a1.12 1.12 0 0 0-1.586 0l-5.937 5.937a3 3 0 0 0-.849 1.697l-.123.86.86-.122a3 3 0 0 0 1.698-.849l5.937-5.937a1.12 1.12 0 0 0 0-1.586M11 4A1 1 0 0 1 10 5c-.998 0-1.702.008-2.253.06-.54.052-.862.141-1.109.267a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216C5.001 8.471 5 9.264 5 10.4v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h3.2c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.126-.247.215-.569.266-1.108.053-.552.06-1.256.06-2.255a1 1 0 1 1 2 .002c0 .978-.006 1.78-.069 2.442-.064.673-.192 1.27-.475 1.827a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C15.6 21 14.727 21 13.643 21h-3.286c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.233-.487-1.961C3 15.6 3 14.727 3 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.729.185-1.369.487-1.961A5 5 0 0 1 5.73 3.545c.556-.284 1.154-.411 1.827-.475C8.22 3.007 9.021 3 10 3A1 1 0 0 1 11 4"></path></svg>
                    </div>
                </div>
                {/* <div className={styles.titleBox}>
                    <div className={styles.title}>对话历史</div>
                    <img onClick={createNewChat} src="chat/add.png" alt="" />
                </div> */}
                {/* <h2 className={styles.time}></h2> */}
                {!isSidebarCollapsed && (<div className={styles.sliderline}></div>)}
                <div className={styles.fileList}>
                    {chatList.map((item: any, index: number) => {
                        return (
                            <div key={index} className={styles.fileItem}>
                                <div onClick={() => { getChatLog(item.thread_id); setCurrentList(item.thread_id) }} title={item.thread_name ? item.thread_name : '未知'} className={`${styles.fileTitle} ellipsis ${item.thread_id == currentList ? styles.active : ''}`} key={item.uuid}>
                                    {item.thread_name ? item.thread_name : '新对话'}
                                </div>
                                <span onClick={() => { deleteChat(item.uuid) }} className={styles.delete}></span>
                            </div>
                        )
                    })}
                </div>
                <div className={styles.smallImageContainer}>
                    <img src="customer.png" alt="Small decorative image" className={styles.smallImage} />
                </div>
                <div>
                <p className={styles.customerText}>扫码免费领不限量使用机会/进群交流</p>
                </div>
                <div className={styles.line}></div>
                <div className={styles.userInfo}>
                        <div className={styles.btnBox}>
                            <div 
                            onClick={() => { setSettingShow(!settingShow);setVisit_page(0) }} 
                            className={`${styles.setting} ${theme === 'light' ? styles.light : ''}`}
                                >
                            </div>
                        </div>
                        <div className={styles.infoContent}>
                            <p className={styles.money} >{userInfo.accaccount_type_name}</p>
                            {userInfo.subscription_expired && <p className={styles.money} >|</p>}
                            {!userInfo.subscription_expired && <p className={styles.slide} >|</p>}
                            {userInfo.subscription_expired && <p
                            className={styles.email}>{userInfo.subscription_expired ? '截止至：' : ''}{userInfo.subscription_expired}</p>}
                            {!userInfo.subscription_expired && <p className={styles.upgrade} onClick={() => { setSettingShow(!settingShow);setVisit_page(1) }}>升级到会员</p>}
                        </div>
                        
                        <div className={styles.btnBox}>
                            <div onClick={() => { Router.push('/login'); localStorage.removeItem('token') }} 
                        className={`${styles.exitBtn} ${theme == 'light' ? styles.light : ''}`}></div>
                        </div>
                </div>
            </div>
            {!settingShow  && <div className={styles.chatpdfBox}>
                {!chatpdfTips && <div className={styles.chatpdfLine} ref={chatContainerRef} onScroll={handleScroll}>
                    {chatContent.map((item: any, index: number) => {
                        return (
                            <div key={index}>
                                {item.user_type == 'user' && item.text && <div key={item.uuid ? item.uuid : index} className={`${styles.chatpdfRow} ${styles.chatpdfAsk}`}>

                                    <div className={styles.chatAskPdfContent}><pre>{item.text}</pre></div>
                                    <div className={styles.chatpdfIcon}></div>
                                </div>}
                                {item.user_type == 'assistant' && item.text && <div key={item.uuid ? item.uuid : index} className={styles.chatpdfRow}>
                                    <div className={styles.chatpdfIcon}></div>

                                    {item.text != '当前对话不存在' && item.text != '订阅已到期' && item.text != '您的今日试用对话机会已用完，请明天继续使用，或者进行会员订阅' && <div className={styles.chatpdfContent}>

                                        {item.text === 'loading' && (!cancleTag ? (
                                            <div className={styles.loadingContainer}>
                                            <img style={{ width: '30px' }} src='/loading/loading2.gif' alt="loading" />
                                            <p className={styles.thinkingText}>Eagle正在思考中</p>
                                          </div>
                                        )
                                             : <p style={{ color: 'red' }}>已取消</p>)}
                                        {item.text !== 'loading' && <TypingEffect ref={typeRef} setMessages={setMessages} giToBottom={giToBottom} setStop={resetFn} thread_id={item.thread_id}
                                            text={item.text} speed={10} />}
                                    </div>}
                                    {(item.text === '当前对话不存在' || item.text === '订阅已到期' || item.text === '您的今日试用对话机会已用完，请明天继续使用，或者进行会员订阅') 
                                    && <div className={styles.chatpdfContent}><p style={{ color: 'red' }}>{item.text}</p></div>}

                                </div>}

                            </div>
                        )
                    })}

                </div>}
                {chatpdfTips && (
                <div className={styles.gptsDiv}>
                    <img src="logo.png" alt="Product Logo" className={styles.productLogo} />
                    <h2 className={styles.productTitle}>Eagle</h2>
                    <p className={styles.productDescription}>Eagle是一个Repo级代码分析AI，它支持通过自然语言对程序代码进行搜索，以及在整个代码库上下文中的代码解读，
                        解决新项目上手和代码维护中的复杂问题。请把你需要解读的项目的GitHub链接及相关问题告诉我，或者以zip包的形式上传给我。（如果文件数量较多，Eagle对Github Repo的处理效果可能明显优于zip包形式）</p>
                        <a 
                            href="https://mp.weixin.qq.com/s/BQ0Z5dfAW243m4noHBKiQw" 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className={styles.guideLink}
                        >
                            Eagle用户使用帮助文档{'>>'}
                        </a>
                </div>
                )}
                <div className={styles.chatpdfTipsContainer}>
                    {chatpdfTips && (
                        <div className={styles.chatpdfTips} onClick={() => { setChatpdfTips(false); setText(tips); markdown.current = tips; }}>
                        {tips}
                        </div>
                    )}
                    {chatpdfTips && (
                        <div className={styles.chatpdfTips} onClick={() => { setChatpdfTips(false); setText(tips2); markdown.current = tips2; }}>
                        {tips2}
                        </div>
                    )}
                </div>
                <div className={styles.chatpdfArea}>
                    <img className={styles.upload} src={theme == 'light' ? 'chat/upload_light.png' : "chat/upload.png"} alt="" />
                    <input disabled={stop} type='file' className={styles.file} onChange={uploadFile} accept='.zip'></input>
                    <p className={styles.file_name}>{fileName}</p>
                    <div className={`${styles.input} ${isExpanded ? styles.expanded : ''}`}>
                        <textarea wrap="off" disabled={stop} style={{
                            color: theme === 'dark' ? '#FFFFFF' : '#000000',
                        }} className={styles.textarea} 
                        placeholder='请在此输入您需要解读的内容' 
                        value={text}
                        onChange={handleInputChange}
                        onKeyDown={keyupFun}
                        ></textarea>
                    </div>

                    {!stop && <img className={styles.send} onClick={createChat} src="chat/send.png" alt="" />}
                    {/* @ts-ignore */}
                    {stop && <svg onClick={cancleFn} className={styles.svg} t="1722916312730" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10686" width="48" height="48"><path d="M510.407736 960.414823c-60.290131 0-118.797663-11.81714-173.898609-35.122939-53.199643-22.50148-100.970646-54.706973-141.983735-95.720062-41.013089-41.013089-73.218582-88.784092-95.720062-141.983735-23.305799-55.101969-35.122939-113.608477-35.122939-173.898609 0-60.290131 11.81714-118.797663 35.122939-173.898609 22.50148-53.200666 54.706973-100.970646 95.720062-141.983735 41.013089-41.014112 88.784092-73.218582 141.983735-95.721085 55.101969-23.305799 113.608477-35.122939 173.898609-35.122939s118.797663 11.81714 173.898609 35.122939c53.199643 22.502503 100.970646 54.706973 141.983735 95.721085 41.013089 41.013089 73.218582 88.784092 95.720062 141.983735 23.305799 55.100946 35.122939 113.608477 35.122939 173.898609 0 60.290131-11.81714 118.797663-35.122939 173.898609-22.50148 53.199643-54.706973 100.970646-95.720062 141.983735s-88.784092 73.218582-141.983735 95.720062C629.205399 948.597683 570.697867 960.414823 510.407736 960.414823zM510.407736 130.408095c-102.377692 0-198.627826 39.868009-271.0206 112.260783-72.39175 72.392774-112.260783 168.642908-112.260783 271.0206s39.868009 198.627826 112.260783 271.0206c72.392774 72.392774 168.642908 112.260783 271.0206 112.260783s198.62885-39.868009 271.0206-112.260783c72.392774-72.392774 112.260783-168.642908 112.260783-271.0206s-39.868009-198.627826-112.260783-271.0206C709.036585 170.276105 612.786451 130.408095 510.407736 130.408095z" fill="#CCCCCC" p-id="10687"></path><path d="M348.904435 353.853144l323.007625 0 0 319.671647-323.007625 0 0-319.671647Z" fill="#CCCCCC" p-id="10688"></path></svg>}
                </div>
                <p className={styles.tips}> 京ICP备2024078129号-1 Copyright © 2024 北京京兆人智能技术有限公司 - 人工智能生成内容仅供参考，使用前请校验内容的正确性。</p>
            </div>}
            {settingShow && <Setting visit_page={visit_page} getInfo={getInfo} userInfo={userInfo} setIsLoading={setIsLoading} showTips={showTips}></Setting>}
            {
                isLoading && <Loading></Loading>
            }
            {
                displayTips && <Tips {...messageInfo}></Tips>
            }

        </div>
    )
}
