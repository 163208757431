import styles from "../styles/setting.module.scss"
import { useEffect, useState, useRef,useContext } from "react"
import http from "../http";
import Page from './page';
import { useRouter } from 'next/router';
import { useTheme } from '../context/ThemeContext';
export default function Setting(props: any) {
    const router = useRouter();
    const { github_id } = router.query;
    const { visit_page, setIsLoading, showTips, userInfo, getInfo } = props
    const [active, setActive] = useState(0)
    const [orderType, setOrderType] = useState(null as any)
    const [pay, setPay] = useState(true)

    let username = useRef<HTMLInputElement>({ value: '' } as any);
    let [email, setEmail] = useState(userInfo.email)
    const [usernameVal, setUsernameVal] = useState(userInfo.username)
    const [password, setPassword] = useState('');
    const [passwordSure, setPasswordSure] = useState('')
    
    const [phone, setPhone] = useState(userInfo.phone_number)
    const themeRef = useRef<HTMLInputElement>({ value: '' } as any);
    const [showEdit, setShowEdit] = useState(false);
    const [password_iconshow, setPassword_iconshow] = useState(false);
    const [theme_iconshow, setTheme_iconshow] = useState(false);
    const [code, setCode] = useState('')
    const [count, setCount] = useState(0)
    const [page_index, setPage_index] = useState(1)
    const [page_num, setPage_num] = useState(10)
    const [pageCount, setPageCount] = useState(0)
    
    const [showPage, setShowPage] = useState(false)
    const [changePhone, setChangePhone] = useState(false)
    const userEdit = useRef({} as any)
    const { theme, toggleTheme } = useTheme();
    const [themeVal, setThemeVal] = useState(theme)
    const [logList, setLogList] = useState([] as any)
    const price: any = {
        daily: '9.9',
        seasonal: '228',
        yearly: '990',
        weekly: '59',
    }
    const originalPrice: any = {
        seasonal: '298'
    }
    useEffect(() => {
        active == 2&&getLog()
    },[page_index])
    useEffect(() => {
        github_id&&linkGithub()
        if(visit_page){
            setActive(visit_page)
        }
    },[])
    const bindCard = () => {
        let data = {
            "out_trade_no": "string",
            "total_amount": price[orderType],
            "subject": orderType,
            "body": orderType
        }
        setIsLoading(true)
        http.post("/api/v1/payment/pay", data).then((res: any) => {
            if (res.status) {
                let divForm = document.getElementsByTagName('divform')
                if (divForm.length) {
                    document.body.removeChild(divForm[0])
                }
                const div = document.createElement('divform');
                div.innerHTML = res.data;
                document.body.appendChild(div);
                document.forms[0].acceptCharset = 'UTF-8';
                document.forms[0].setAttribute('target', '_blank')

                //保持与支付宝默认编码格式一致，如果不一致将会出现：调试错误，请回到请求来源地，重新发起请求，错误代码 invalid-signature 错误原因: 验签出错，建议检查签名字符串或签名私钥与应用公钥是否匹配
                document.forms[0].submit();
            } else {
                showTips(res.message, false)
            }
            setIsLoading(false)
        })
    }
    const getCode = () => {
        let number = phone.current.value
        if (!number) {
            showTips('请输入手机号', false)
            return
        } else if (!(/^1[3456789]\d{9}$/.test(number))) {
            showTips('请输入正确的手机号', false)
            return
        }
        http.post('/api/v1/account/code/', { "phone_number": number }).then((res: any) => {
            if (res.status) {
               // showTips(res.message, true)
            } else {
                showTips(res.message, false)
            }
        })
    }
    const initPatient = (num: number) => {
        //debugger
        setPage_index(num)

    }
    const saveInfo = () => {
        toggleTheme(themeVal)
        if (password === passwordSure) {
            if (password != '') {
                userEdit.current.password = password
            }

        } else {
            showTips('两次密码不一致', false)
            return
        }
        http.put('/api/v1/account/edit', userEdit.current).then((res: any) => {
            if (res.status) {
              //  showTips(res.message, true)
                setShowEdit(false)
                userEdit.current = {}
                getInfo()
            } else {
                showTips(res.message, false)
            }
        })
    }
    const getLog=()=>{
        http.get(  `/api/v1/payment/order_history?page=${page_index}&page_size=${page_num}`).then((res: any) => {
            if (res.status) {
                setLogList(res.data.orders)
                setPageCount(res.data.total_pages)
                setCount(res.data.total_orders)
                setShowPage(res.data.total_pages> 1);
              //  showTips(res.message, true)
            } else {
                showTips(res.message, false)
            }
        })
    }
    const goGithub = () => {
        if(userInfo.is_bind_github) return
        http.get('/api/v1/thirdparty/github/login').then((res: any) => {
            if(res.status){
                window.open(res.data.authorization_url)
            }
        })
    }
    const linkGithub = () => {
        http.post(`/api/v1/thirdparty/github/link?github_id=${github_id}`).then(
            (data: any) => {
                if (data.status) {
                  //  showTips(data.message, true);
                    router.replace('/')
                } else {
                    showTips(data.message, false);
                }
            }
        )
     }
    return (
        <div className={styles.wraper}>
            <ul className={styles.list}>
                <li className={active == 0 ? styles.active : undefined} onClick={() => { setActive(0) }}><img src="/setting/home.png"></img>个人信息</li>
                <li className={active == 1 ? styles.active : undefined} onClick={() => { setActive(1) }}><img src="/setting/card.png"></img>购买会员</li>
                <li className={active == 2 ? styles.active : undefined} onClick={() => { setActive(2); getLog() }}><img src="/setting/log.png"></img>支付历史</li>
            </ul>
            {active == 0 && <div className={styles.form}>
                <div className={styles.info_part}>
                    <div className={styles.info_label}>用户名</div>
                    <input disabled={!showEdit} className={!showEdit ? styles.info_input : ''} value={usernameVal} onChange={(e) => { setUsernameVal(e.currentTarget.value); userEdit.current.username = e.currentTarget.value }}
                        placeholder="请输入您的用户名" />

                </div>
                <div className={styles.info_part}>
                    <div className={styles.info_label}>用户邮箱</div>
                    <input disabled={!showEdit} className={!showEdit ? styles.info_input : ''} value={email} onChange={(e) => { setEmail(e.currentTarget.value); userEdit.current.email = e.currentTarget.value }}
                        placeholder="请输入您的邮箱" />

                </div>
                <div className={styles.info_part}>
                    <div className={styles.info_label}>手机号</div>
                    <input disabled={!showEdit} className={!showEdit ? styles.info_input : ''} value={phone} onChange={(e) => { setPhone(e.currentTarget.value); setChangePhone(true); userEdit.current.phone_number = e.currentTarget.value }}
                        placeholder="请输入您的手机号" />

                </div>
                {showEdit && changePhone && <div className={styles.info_part}>
                    <div className={styles.info_label}>验证码</div>
                    <input value={code} onChange={(e) => { setCode(e.currentTarget.value); userEdit.current.sms_code = e.currentTarget.value }}
                        placeholder="请输入短信验证码"
                        type="text" />
                    <div className={styles.send_code}
                        onClick={() => getCode()}>
                        发送验证码
                    </div>
                </div>}
                <div className={styles.info_part}>
                    <div className={styles.info_label}>密码</div>
                    <input disabled={!showEdit} className={!showEdit ? styles.info_input : ''}
                        value={showEdit?password:"******"} onChange={(e) => { setPassword(e.currentTarget.value) }}
                        placeholder="请输入您的密码" />

                </div>
                {showEdit && <div className={styles.info_part}>
                    <div className={styles.info_label}>密码确认</div>
                    <input value={passwordSure} onChange={(e) => { setPasswordSure(e.currentTarget.value) }}
                        placeholder="请确认您的密码"
                        type={password_iconshow ? 'text' : 'password'} />
                    <div className={password_iconshow ? `${styles.icons_show} ${styles.icons}` : styles.icons} onClick={() => setPassword_iconshow(!password_iconshow)}></div>
                </div>}
                {/* <div className={styles.info_part}>
                    <div className={styles.info_label}>主题</div>
                    <input disabled={!showEdit} className={!showEdit ? styles.info_input : ''} readOnly value={themeVal=='dark'?'深色':'浅色'}
                        placeholder="请选择主题" />
                    <div className={theme_iconshow ? `${styles.icons_show} ${styles.select}` : styles.select} onClick={() => showEdit && setTheme_iconshow(!theme_iconshow)}></div>
                    {theme_iconshow && <ul className={styles.selectDown}>
                        <li onClick={() => { setThemeVal('dark'); setTheme_iconshow(false) }}>深色</li>
                        <li onClick={() => { setThemeVal('light');;  setTheme_iconshow(false) }}>浅色</li>
                    </ul>}
                </div> */}
                {!showEdit && <div className={styles.third_part}>
                    <span>第三方应用：</span>
                    <img src={userInfo.is_bind_wechat ? "/setting/wechat.png" : "/setting/wechat_hui.png"}></img>
                    <img onClick={goGithub} src={userInfo.is_bind_github ? "/setting/github.png" : "/setting/github_hui.png"}></img>
                </div>}
                {showEdit && <div className={styles.saveBtn} onClick={saveInfo}>保存</div>}
                {!showEdit && <div className={styles.saveBtn} onClick={() => { setShowEdit(true) }}>修改</div>}
            </div>}
            {active == 1 && <div className={styles.pay}>
                <div className={styles.method}><span>支付方式：</span> <p onClick={() => { setPay(!pay) }} className={styles.ali}><img src={pay ? "/setting/checked.png" : '/setting/uncheck.png'}></img> 支付宝</p></div>
                <div className={styles.order}>
                    <span>会员类型：</span>
                    <div className={styles.orderTypes}>
                        <p onClick={() => { setOrderType('daily') }} className={styles.type}><img src={orderType === 'daily' ? "/setting/checked.png" : '/setting/uncheck.png'}></img> 不限量畅聊6小时</p>
                        <p onClick={() => { setOrderType('weekly') }} className={styles.type}><img src={orderType === 'weekly' ? "/setting/checked.png" : '/setting/uncheck.png'}></img> 周卡，不限量使用一周</p>
                        <p onClick={() => { setOrderType('seasonal') }} className={styles.type}><img src={orderType === 'seasonal' ? "/setting/checked.png" : '/setting/uncheck.png'}></img> 季卡，不限量使用90天</p>
                        <p onClick={() => { setOrderType('yearly') }} className={styles.type}><img src={orderType === 'yearly' ? "/setting/checked.png" : '/setting/uncheck.png'}></img> 年卡，不限量使用一年</p>
                    </div>
                </div>
                <div className={styles.price}>
                    <span>支付金额：</span>
                    {orderType && (
                        <p>
                            {originalPrice[orderType] && (
                                <span className={styles.originalPrice}>{originalPrice[orderType]}元</span>
                            )}
                            <span>
                                {originalPrice[orderType] ? '限时特价' : ''}
                                {price[orderType]}元
                            </span>
                        </p>
                    )}
                </div>
                {/* <p className={styles.tips}>*请点击绑定跳转支付宝授权页面进行绑定</p> */}
                <button onClick={bindCard} className={styles.bindCard}>支付</button>
            </div>}
            {active == 2 && <div className={styles.log}>
                <div className={styles.table}>
                    <table>
                        <thead><tr><th>支付时间</th><th>支付方式</th><th>支付金额</th><th>支付渠道</th><th>支付状态</th></tr></thead>
                        <tbody>
                            {logList.map((item:any, index:any) => {
                                return (<tr key={item.uuid}><td>{item.timestamp}</td><td>{item.payment_type}</td><td>{item.amount}</td><td>{item.payment_method}</td><td>{item.status}</td></tr>)
                            })}
                           </tbody>
                    </table>
                </div>
                <div className={styles.page_tit}>
                    <div>数据 {count}</div>
                    {pageCount>1 && <Page
                        {...{ pageCount, isDialog: true, currentPage: page_index, initPatient }}
                    ></Page>
                    }
                </div>
            </div>}
        </div>
    )
}