import axios from 'axios';
import Router from 'next/router'
const api = axios.create({

});

// 设置axios拦截器: 响应拦截器
api.interceptors.response.use(res => {
    // 成功响应的拦截
    switch(res.data.code){
     
        // 对得到的状态码的处理，具体的设置视自己的情况而定
        case 401:
          //  console.log('token过期')
            
            Router.push('/login')

            return Promise.resolve(res.data)
        // case 404:
        //     window.location.href='/'
        //     break
        // case 405:
        //     console.log('不支持的方法')
        //     break
        // case ...
        default:
            return Promise.resolve(res.data)
            
      }
    
  }, err =>{
    // 失败响应的拦截
    if(err.response){
      // 失败响应的status需要在response中获得
      // console.log(err.response)
      switch(err.response.status){
        // 对得到的状态码的处理，具体的设置视自己的情况而定
        case 401:
         //   console.log('token过期')
            Router.push('/login')
            break
        // case 404:
        //     window.location.href='/'
        //     break
        // case 405:
        //     console.log('不支持的方法')
        //     break
        // case ...
        default:
          //  console.log('其他错误')
            break
      }
    }
    // 注意这里应该return promise.reject(),
    // 因为如果直接return err则在调用此实例时，响应失败了也会进入then(res=>{})而不是reject或catch方法
    return Promise.reject(err)
  })
  api.interceptors.request.use(config => {
    if(config.url === '/api/v1/login/') return config
    config.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
   
    return config
  })
 
export default api
