// components/TypingEffect.js
import React, { useState, useEffect,useRef,useImperativeHandle,forwardRef } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import 'highlight.js/styles/github.css'; // 导入代码高亮的CSS样式
import 'highlight.js/styles/monokai-sublime.css'; // 导入代码高亮的CSS样式
import 'highlight.js/styles/atom-one-light.css'; // 导入代码高亮的CSS样式
import 'highlight.js/styles/atom-one-dark.css'; // 导入代码高亮的CSS样式
import hljs from 'highlight.js';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css'; // KaTeX 样式
import { Terminal } from "lucide-react";
import CopyButton from './CopyButton';
import { useTheme } from '../context/ThemeContext';
const languageSet = [
  "1c",
  "abnf",
  "accesslog",
  "ada",
  "arduino",
  "ino",
  "armasm",
  "arm",
  "avrasm",
  "actionscript",
  "as",
  "angelscript",
  "asc",
  "apache",
  "apacheconf",
  "applescript",
  "osascript",
  "arcade",
  "asciidoc",
  "adoc",
  "aspectj",
  "autohotkey",
  "autoit",
  "awk",
  "mawk",
  "nawk",
  "gawk",
  "bash",
  "sh",
  "zsh",
  "basic",
  "brainfuck",
  "bf",
  "csharp",
  "cs",
  "c",
  "h",
  "cpp",
  "hpp",
  "cc",
  "hh",
  "c++",
  "h++",
  "cxx",
  "hxx",
  "cal",
  "cos",
  "cls",
  "cmake",
  "cmake.in",
  "coq",
  "csp",
  "css",
  "capnproto",
  "capnp",
  "clojure",
  "clj",
  "coffeescript",
  "coffee",
  "cson",
  "iced",
  "crmsh",
  "crm",
  "pcmk",
  "crystal",
  "cr",
  "d",
  "dart",
  "dpr",
  "dfm",
  "pas",
  "pascal",
  "diff",
  "patch",
  "django",
  "jinja",
  "dns",
  "zone",
  "bind",
  "dockerfile",
  "docker",
  "dos",
  "bat",
  "cmd",
  "dsconfig",
  "dts",
  "dust",
  "dst",
  "ebnf",
  "elixir",
  "elm",
  "erlang",
  "erl",
  "excel",
  "xls",
  "xlsx",
  "fsharp",
  "fs",
  "fsx",
  "fsi",
  "fsscript",
  "fix",
  "fortran",
  "f90",
  "f95",
  "gcode",
  "nc",
  "gams",
  "gms",
  "gauss",
  "gss",
  "gherkin",
  "go",
  "golang",
  "golo",
  "gololang",
  "gradle",
  "graphql",
  "gql",
  "groovy",
  "xml",
  "html",
  "xhtml",
  "rss",
  "atom",
  "xjb",
  "xsd",
  "xsl",
  "plist",
  "svg",
  "http",
  "https",
  "haml",
  "handlebars",
  "hbs",
  "html.hbs",
  "html.handlebars",
  "haskell",
  "hs",
  "haxe",
  "hx",
  "hy",
  "hylang",
  "ini",
  "toml",
  "inform7",
  "i7",
  "irpf90",
  "json",
  "jsonc",
  "java",
  "jsp",
  "javascript",
  "js",
  "jsx",
  "julia",
  "jl",
  "julia-repl",
  "kotlin",
  "kt",
  "tex",
  "lasso",
  "ls",
  "lassoscript",
  "less",
  "ldif",
  "lisp",
  "livecodeserver",
  "livescript",
  "lookml",
  "lua",
  "makefile",
  "mk",
  "mak",
  "make",
  "markdown",
  "md",
  "mkdown",
  "mkd",
  "mathematica",
  "mma",
  "wl",
  "matlab",
  "maxima",
  "mel",
  "mercury",
  "mips",
  "mipsasm",
  "mojolicious",
  "monkey",
  "moonscript",
  "moon",
  "n1ql",
  "nsis",
  "nginx",
  "nginxconf",
  "nim",
  "nimrod",
  "nix",
  "ocaml",
  "ml",
  "objectivec",
  "mm",
  "objc",
  "obj-c",
  "obj-c++",
  "objective-c++",
  "glsl",
  "openscad",
  "scad",
  "ruleslanguage",
  "oxygene",
  "pf",
  "pf.conf",
  "php",
  "parser3",
  "perl",
  "pl",
  "pm",
  "plaintext",
  "txt",
  "text",
  "pony",
  "pgsql",
  "postgres",
  "postgresql",
  "powershell",
  "ps",
  "ps1",
  "processing",
  "prolog",
  "properties",
  "proto",
  "protobuf",
  "puppet",
  "pp",
  "python",
  "py",
  "gyp",
  "profile",
  "python-repl",
  "pycon",
  "k",
  "kdb",
  "qml",
  "r",
  "reasonml",
  "re",
  "rib",
  "rsl",
  "graph",
  "instances",
  "ruby",
  "rb",
  "gemspec",
  "podspec",
  "thor",
  "irb",
  "rust",
  "rs",
  "sas",
  "scss",
  "sql",
  "p21",
  "step",
  "stp",
  "scala",
  "scheme",
  "scilab",
  "sci",
  "shell",
  "console",
  "smali",
  "smalltalk",
  "st",
  "sml",
  "sol",
  "stan",
  "stanfuncs",
  "stata",
  "stylus",
  "styl",
  "subunit",
  "swift",
  "tcl",
  "tk",
  "tap",
  "thrift",
  "twig",
  "craftcms",
  "typescript",
  "ts",
  "tsx",
  "mts",
  "cts",
  "vbnet",
  "vb",
  "vbscript",
  "vbs",
  "vhdl",
  "vala",
  "verilog",
  "v",
  "vim",
  "xl",
  "tao",
  "xquery",
  "xpath",
  "xq",
  "xqm",
  "yml",
  "yaml",
  "zephir",
  "zep"]
  const TypingEffect = ({ text='', speed = 20, thread_id=null,setStop=()=>{},giToBottom=()=>{},setMessages=(flage:any)=>{} },ref:any) => {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);
  const [stopTimer, setStopTimer] = useState(true); // 停止计时
  const { theme, toggleTheme } = useTheme();
  const timeout=useRef('' as any)
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = theme === 'light'
      ? '/github.css'
      : '/atom-one-dark.css'; // 你可以根据需要选择其他 dark 主题
    document.head.appendChild(link);
    hljs.highlightAll();

    return () => {
      document.head.removeChild(link);
    };
  }, [theme]);
  useEffect(() => {
    // 每次 messages 更新时，自动滚动到底部
    giToBottom()
    //console.log('giToBottom')
  }, [text, displayedText]);
  // useEffect(() => {
  //   if(thread_id) return
  //   if (index < text.length&&stopTimer) {
  //     timeout.current = setTimeout(() => {
  //       setDisplayedText(displayedText + text[index]);
  //       setIndex(index + 1);
  //     }, speed);
  //     return () => clearTimeout( timeout.current);
  //   }else{
  //     setStop();
  //     clearTimeout( timeout.current)
  //    // setMessages('')
  //   }
  // }, [index, text, displayedText, speed]);
  useImperativeHandle(ref, () => {
    return { clearTimeout: () => {clearTimeout( timeout.current); setStopTimer(false)},setStopTimer:()=>setStopTimer(true)}
  })

  return <ReactMarkdown 
  remarkPlugins={[
    remarkGfm,
    [remarkMath, { singleDollarTextMath: true }],
  ]}
  // @ts-ignore
  rehypePlugins={[rehypeKatex,[rehypeHighlight, { ignoreMissing: 'true' }]]}
  components={{
    pre: ({ children }) => <pre className="not-prose">{children}</pre>,
    code: ({ node, className, children, ...props }) => {
      const match = /language-(\w+)/.exec(className || "");
      if (match?.length) {
        const id = Math.random().toString(36).substr(2, 9);
        return (
          <div className={`not-prose rounded-md  border-2  ${theme === 'dark' ? 'border-black' : 'border-gray'}`}>
            <div className={`flex h-12 items-center justify-between px-4 ${theme=='light'?' bg-zinc-100 dark:bg-zinc-900':'bg-zinc-900'}`}>
              <div className="flex items-center gap-2">
                <Terminal size={16} />
                <p className={`text-sm ${theme.current=='light'?'text-zinc-600 dark:text-zinc-400':''}`}>
                  {node?.data?.meta}
                </p>
              </div>
              <CopyButton id={id} />
            </div>
            <div className={`overflow-x-auto`}>
              <div id={id} className="p-4">
                {children}
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <code
            {...props}
            className={`not-prose rounded  px-1 ${theme=='light'?' bg-gray-100 dark:bg-zinc-900':'bg-zinc-900'}`}
          >
            {children}
          </code>
        );
      }
    },
  }}
  className={`max-w-full overflow-x-auto prose ${theme === 'light' ? 'prose-zinc' : 'prose-invert'}`}
  >{text}</ReactMarkdown>;
};

export default forwardRef(TypingEffect);